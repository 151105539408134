import axios from 'axios';

const apiIneEndpoint = '/api/ine_data.json';
const apiBanData = '/api/ban_data.json';
const apiMarketWatch = '/api/market_watch_data.json';

export function getIneData() {
    return axios.get(apiIneEndpoint)
        .then((response) => {
            const {data} = response;
            if(data) {
                const {ine_data} = data;
                return ine_data;
            } else { return null;}
        })
        .catch((error) => {
            return null;
        });
}

export function getBanData() {
    return axios.get(apiBanData)
        .then((response) => {
            const {data} = response;
           return data;
        })
        .catch((error) => {
            return null;
        });
}

export function getMarketWatch() {
    return axios.get(apiMarketWatch)
        .then((response) => {
            const {data} = response;
           return data;
        })
        .catch((error) => {
            return null;
        });
}