import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Indices from './Indices';
import IndicesAnnual from './IndicesAnnual';
import PIB from './PIB';
import Population from './Population';
import { getIneData, getBanData } from '../api';
import CircularProgress from '@mui/material/CircularProgress';
import Bancomoc from './Bancomoc';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';


const mdTheme = createTheme();

function Report() {
    const [ineData, setIneData] = useState(null);
    const [banData, setBanData] = useState(null);
    const [showReport1, setshowReport1] = useState(true);

    useEffect(() => {

        const fetchIneData = async () => {
            try {
                getIneData().then((data) => {
                    setIneData(data);
                });

                getBanData().then((data) => {
                    setBanData(data);
                });
            } catch (error) {
                console.error(error);
            }
        }

        fetchIneData();

        const interval = setInterval(() => {
            fetchIneData();
        }, 1800000);

        const screenInterval = setInterval(() => {
            setshowReport1((prevFlag) => !prevFlag);
        }, 10000);

        return () => {
            clearInterval(screenInterval);
            clearInterval(interval);
        };

     

    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
             <Box>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1.5}>
                    <Grid item xs={12} textAlign="left">
                       <div className='r2-header-wrapper'>
                            <div className='report2-header'>
                                <img src="https://zilotesting.co.za/amaramba/wp-content/uploads/2023/02/LOGO.png" className='report2-logo' alt="Amaramba" style={{'width':180, height:130}} />                               
                            </div>   
                        </div>                     
                    </Grid>
                </Grid>

            </Box>
            <Box sx={{ height: '100vh', width: '98%', padding: 2, backgroundColor:'#00325b' }}>{ineData ? (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} style={{padding:25}}>                            
                        <Typography variant="h4" color="#ffffff" textAlign="center">{showReport1 ? "INSTITUTO NACIONAL DE ESTATÍSTICA":"BANCO DE MOÇAMBIQUE"}</Typography>                                           
                    </Grid>
                    {showReport1 ? <>
                        <Slide direction="right" in={true}><Grid item xs={6}>
                        {ineData ? (<Indices ineData={ineData}></Indices>) : <CircularProgress></CircularProgress>}
                    </Grid></Slide>
                    <Slide direction="left" in={true}><Grid item xs={6}>
                        {ineData ? (<Population ineData={ineData}></Population>) : <CircularProgress></CircularProgress>}
                    </Grid></Slide>
                    <Slide direction="right" in={true}><Grid item xs={6} style={{paddingTop:30}}>
                        {ineData ? (<IndicesAnnual ineData={ineData}></IndicesAnnual>) : <CircularProgress></CircularProgress>}
                    </Grid></Slide>
                    <Slide direction="left" in={true}><Grid item xs={6} style={{paddingTop:30}}>
                        {ineData ? (<PIB ineData={ineData}></PIB>) : <CircularProgress></CircularProgress>}
                    </Grid></Slide>
                    </>:
                     <Slide direction="up" in={true}><Grid item xs={12}>
                                {banData ? (<Bancomoc banData={banData}></Bancomoc>) : <CircularProgress></CircularProgress>}
                    </Grid></Slide>}
                </Grid>) :
                <Box sx={{ display: 'flex' }} textAlign="center">
                    <CircularProgress />
                </Box>
            }
            </Box>
        </ThemeProvider>
    );
}

export default Report;