import { styled, useTheme } from '@mui/material/styles';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, Chip, Grid, Stack, Typography, Card } from '@mui/material';

function Analytic ({ color, title, count, percentage, isLoss, extra }) {
    
    const theme = useTheme();

    return(
    <Card elevation={2} sx={{
        sx: { p: 2.25 },
        border: '1px solid',
        borderRadius: 2,
        borderColor: '#e6e6e6',
        boxShadow: theme.palette.grey.A800,
        '& pre': {
          m: 0,
          p: '16px !important',
          fontFamily: theme.typography.fontFamily,
          fontSize: '0.75rem'
        },
        padding:2
      }}>
    <Stack spacing={0.5}>
        <Typography component="span" variant="h6" sx={{ color: `${color || 'primary'}.main` }}>
            {title}
        </Typography>
        <Grid container alignItems="center">
            <Grid item>
                <Typography variant="h4" color="inherit">
                    {count}
                </Typography>
            </Grid>
            {percentage && (
                <Grid item>
                    <Chip
                        variant="combined"
                        color={isLoss?"error":"success"}
                        icon={
                            <>
                                {!isLoss && <TrendingUpIcon style={{ fontSize: '0.75rem', color: 'white' }} />}
                                {isLoss && <TrendingDownIcon style={{ fontSize: '0.75rem', color: 'white' }} />}
                            </>
                        }
                        label={`${percentage}`}
                        sx={{ ml: 1.25, pl: 1 }}
                        size="small"
                    />
                </Grid>
            )}
        </Grid>
    </Stack>
    <Box sx={{ pt: 2.25 }}>
        <Typography variant="caption" color="textSecondary">
            The last change {' '}
            <Typography component="span" variant="h6" sx={{ color: `${color || 'primary'}.main` }}>
               <strong>{extra}</strong>
            </Typography>{' '}
            for today
        </Typography>
    </Box>
</Card>
)};

export default Analytic;