import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5cb2e9',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Bancomoc({ banData }) {

  return (
    <Paper elevation={3}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ paddingLeft: 10 }}>
        {banData.title}
      </Typography>
      <Table size="small">
        <TableBody>
          <StyledTableRow>
              <TableCell> <strong>{banData.cols[0]}</strong></TableCell>
              <TableCell><strong>{banData.cols[1]}</strong></TableCell>
              <TableCell><strong>{banData.cols[2]}</strong></TableCell>
              <TableCell><strong>{banData.cols[3]}</strong></TableCell>
              <TableCell><strong>{banData.cols[4]}</strong></TableCell>
          </StyledTableRow>
          {banData.data.map((row, index) => (
           
            <StyledTableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              key={index}
            >
                <TableCell component="th" scope="row">
                  {row[0]}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row[1]}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row[2]}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row[3]}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row[4]}
                </TableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default Bancomoc;