import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#0c5f95',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function Indices({ineData}) {
    const {consumer_price_index} = ineData;
    return (
        <Paper elevation={3}>           
            <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ paddingLeft: 10 }}>
                {consumer_price_index.title}
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell align="center" colSpan={4} sx={{ backgroundColor: "#eeeee4" }}>Variação (Inflação) %</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell align="right">Índices</StyledTableCell>
                        <StyledTableCell align="right">Mensal</StyledTableCell>
                        <StyledTableCell align="right">Acumulada</StyledTableCell>
                        <StyledTableCell align="right">Homóloga</StyledTableCell>
                        <StyledTableCell align="right">Média 12 Meses</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {consumer_price_index.data.map((row) => (
                        <StyledTableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.indexes}</StyledTableCell>
                            <StyledTableCell align="right">{row.monthly}</StyledTableCell>
                            <StyledTableCell align="right">{row.accumulated}</StyledTableCell>
                            <StyledTableCell align="right">{row.homologous}</StyledTableCell>
                            <StyledTableCell align="right">{row.avg}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default Indices;