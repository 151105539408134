import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Chip, Grid, Stack } from '@mui/material';
import Analytic from './Analytic';
import CircularProgress from '@mui/material/CircularProgress';

import { styled, useTheme } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5cb2e9',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function MarketWatchData1({ data, market }) {
  let marketData = '';
  let title = '';
  if (market == 'us') {
    marketData = data.us_data.filter(x => x.name == 'Dow' || x.name == 'S&P 500' || x.name == 'Nasdaq');
    title = 'US';
  } else if (market == 'europe') {
    marketData = data.europe_data.filter(x => x.name == 'FTSE 100' || x.name == 'DAX');
    title = 'Europe';
  } else if (market == 'asia') {
    marketData = data.asia_data.filter(x => x.name == 'Nikkei 225' || x.name == 'Hang Seng' || x.name == 'Shanghai' || x.name == 'Singapore');
    title = 'Asia';
  } else if (market == 'asx') {
    if (data.au_asx_data) {
      marketData = [data.au_asx_data]
      title = 'AU';
    }
  }
  

  return (marketData ? (
    //<Paper style={{padding:10}} variant="outlined">
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        {/* row 1 */}
        <Grid item xs={1} textAlign="center">
          <Typography variant="h5" style={{'marginTop':50, color:'#FFFFFF'}}>{title}</Typography>
        </Grid>
        {marketData.length > 0 && marketData.map((row) => (
          <Grid item xs={1} sm={6} md={4} lg={2.5}>
            {row.last && !/[\u007F-\uFFFF]/u.test(row.last)?
           <Analytic title={row.name} count={row.last} percentage={row.percentchange} extra={row.change} isLoss={Number(row.percentchange.replace('%',''))>=0?false:true} />
           :
           <CircularProgress></CircularProgress>}
          </Grid>
        ))}
      </Grid>
    //</Paper>
  ) : "");
}

export default MarketWatchData1;