import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MarketWatchData from './marketWatch';
import { getMarketWatch } from '../api';
import CircularProgress from '@mui/material/CircularProgress';
const mdTheme = createTheme();

function Report1() {
    const [marketWatchData, setMarketWatchData] = useState(null);

    useEffect(() => {
        const fetchMarketData = async () => {
            try {
                getMarketWatch().then((data) => {
                    setMarketWatchData(data);
                });
            } catch (error) {
                console.error(error);
            }
        }

        fetchMarketData();

        const interval = setInterval(() => {
            fetchMarketData();
        }, 10000);

        return () => clearInterval(interval);

    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ height: '100vh', width: '98%', padding: 2 }}>{marketWatchData ? (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <MarketWatchData data={marketWatchData} market='us'></MarketWatchData>
                    </Grid>
                    <Grid item xs={6}>
                        <MarketWatchData data={marketWatchData} market='europe'></MarketWatchData>
                    </Grid>
                    <Grid item xs={6}>
                        <MarketWatchData data={marketWatchData} market='asia'></MarketWatchData>
                    </Grid>
                    <Grid item xs={6}>
                        <MarketWatchData data={marketWatchData} market='asx'></MarketWatchData>
                    </Grid>
                    
                </Grid>) :
                <Box sx={{ display: 'flex' }} textAlign="center">
                    <CircularProgress />
                </Box>
            }
            </Box>
        </ThemeProvider>
    );
}

export default Report1;