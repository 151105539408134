import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Report from './components/report';
import Report1 from './components/report1';
import Report2 from './components/report2';
import PageNotFound from './components/404';

const App = () => {
  return (
    <Routes>
         <Route path='/' element={ <Report /> } />
         <Route path='/report1' element={ <Report /> } />
         <Route path='/report2' element={ <Report2 /> } />
         <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
