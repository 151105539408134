import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5cb2e9',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function MarketWatchData({data, market}) {
    let marketData = '';
    let title = '';
    if(market == 'us') {
      marketData = data.us_data.filter(x=>x.name == 'Dow' || x.name=='S&P 500' || x.name=='Nasdaq');
      title = 'US';
    } else if(market == 'europe') {
      marketData = data.europe_data.filter(x=>x.name == 'FTSE 100' || x.name=='DAX');
      title = 'Europe';
    } else if(market == 'asia') {
      marketData =  data.asia_data.filter(x=>x.name == 'Nikkei 225' || x.name=='Hang Seng' || x.name=='Shanghai' || x.name=='Singapore');
      title = 'Asia';
    }else if(market == 'asx') {
      if(data.au_asx_data) {
      marketData =  [data.au_asx_data]
      title = 'AU';
      }
    }
    return (marketData? (
        <Paper elevation={3}>
          
            <Table size="small">     
            <StyledTableRow>
            <TableCell colSpan={5} style={{backgroundColor: (market == 'asx'? '#065068': market =='asia'? '#065068' : '#065068')}}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ paddingLeft: 10, color:'white'}}>
            <strong>{title}</strong>
            </Typography></TableCell></StyledTableRow>   
                <TableBody>              
                    {marketData.length>0 && marketData.map((row) => (
                        <StyledTableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                           <TableCell>
                             {Number(row.change)>=0?( <ArrowDropUpIcon sx={{ color: 'green' }}></ArrowDropUpIcon>):( <ArrowDropDownIcon sx={{ color: 'red' }}></ArrowDropDownIcon>)}
                          
                           </TableCell>
                            <TableCell component="th" scope="row">
                               <strong>{row.name}</strong> 
                            </TableCell>
                            <TableCell align="right"><strong>{row.last}</strong></TableCell>
                            <TableCell align="right" style={{color:Number(row.change)>=0?'green':'red'}}><strong>{row.change}</strong></TableCell>
                            <TableCell align="right" style={{color:Number(row.change)>=0?'green':'red'}}><strong>{row.percentchange}</strong></TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    ):"");
}

export default MarketWatchData;