import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import MarketWatchData1 from './marketWatch1';
import { getMarketWatch } from '../api';
import CircularProgress from '@mui/material/CircularProgress';
const mdTheme = createTheme();

function Report2() {
    const [marketWatchData, setMarketWatchData] = useState(null);

    useEffect(() => {
        const fetchMarketData = async () => {
            try {
                getMarketWatch().then((data) => {
                    setMarketWatchData(data);
                });
            } catch (error) {
                console.error(error);
            }
        }

        fetchMarketData();

        const interval = setInterval(() => {
            fetchMarketData();
        }, 10000);

        return () => clearInterval(interval);

    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1.5}>
                    <Grid item xs={12} textAlign="left">
                       <div className='r2-header-wrapper'>
                            <div className='report2-header'>
                                <img src="https://zilotesting.co.za/amaramba/wp-content/uploads/2023/02/LOGO.png" className='report2-logo' alt="Amaramba" style={{'width':180, height:130}} />                               
                            </div>   
                        </div>                     
                    </Grid>
                </Grid>

            </Box>
            <Box sx={{ height: '100vh', width: '98%', padding: 2, backgroundColor:'#00325b' }}>{marketWatchData ? (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                     <Grid item xs={12} style={{padding:15}}>                            
                        <Typography variant="h4" color="#ffffff" textAlign="center">Market Watch</Typography>                                           
                    </Grid>
                    <Grid item xs={12}>
                        <MarketWatchData1 data={marketWatchData} market='us'></MarketWatchData1>
                    </Grid>
                    <Grid item xs={12}>
                        <MarketWatchData1 data={marketWatchData} market='europe'></MarketWatchData1>
                    </Grid>
                    <Grid item xs={12}>
                        <MarketWatchData1 data={marketWatchData} market='asia'></MarketWatchData1>
                    </Grid>
                    <Grid item xs={12}>
                        <MarketWatchData1 data={marketWatchData} market='asx'></MarketWatchData1>
                    </Grid>
                </Grid>
            ) :
                <Box sx={{ display: 'flex' }} textAlign="center">
                    <CircularProgress />
                </Box>
            }
            </Box>
        </ThemeProvider>
    );
}

export default Report2;